$(document).ready(function(){
  $('li.question p').css('display','none');
  $('li.question h3').css('padding-right','20px');
  $('li.question').append('<button>+</button>');
  $('li.question').click(function(){
    $(this).find('p').slideToggle(200);
    var isClosed = $(this).find('button').text() == '+' ? true : false;
    var text = isClosed ? '-' : '+';
    ga('send', 'event', 'FAQ', isClosed ? 'Open' : 'Close', $(this).find('h3').text());
    $(this).find('button').text(text);
    //$(this).find('button').innerHTML = ($(this).find('button').innerHTML==="+") ? "-" : "+";
  });
});



if (window.location.href.match(/responsiveform=true/g)) {
  document.cookie = "responsiveform=true";
}
if (window.location.href.match(/responsiveform=false/g)) {
  document.cookie = "responsiveform=false";
}
if (document.cookie.indexOf("responsiveform=false") == -1) {
  (function() {
    var break960;
    var responsive = function () {
      if (window.matchMedia("(min-width: 960px)").matches) {
        if(break960 != "+"){
          //$('section.form').insertAfter($('section.com'));
          break960 = "+";
        }
      } else {
        if(break960 != "-"){
          //$('section.form').insertAfter($('p.intro_com'));
          break960 = "-";
        }
      }
    };
    $(document).ready(function(){
      if (window.matchMedia("(min-width: 960px)").matches) {
        $('div.g-recaptcha').attr('data-size','normal');
      } else {
        $('div.g-recaptcha').attr('data-size','compact');
      }
      responsive();
    });
    $(window).resize(responsive);
  }());
}



var cookieAlertValue = 'vw-cookie-accept=1';

var cookieAlertExpires = new Date();
cookieAlertExpires.setTime(cookieAlertExpires.getTime() + (15 * 24 * 60 * 60 * 1000));
cookieAlertExpires = 'expires=' + cookieAlertExpires.toUTCString();

var cookieAlertPage = '/mentions-legales/';

//Clic sur la croix
var cookieAlertClose = function() {
  document.cookie = cookieAlertValue + "; " + cookieAlertExpires;
  ga('send', 'event', 'Alerte cookies', 'Fermeture', {'nonInteraction': 1});
  $('.vw-cookie-alert').fadeOut();
};

//Clic sur le lien
var cookieAlertMore = function() {
  ga('send', 'event', 'Alerte cookies', 'Savoir plus', {'nonInteraction': 1});
};
var vwCookieAlertBloc = '';

//Ouverture automatique (si cookies activÃ©s sur navigateur + cookie non prÃ©sent )
if (navigator.cookieEnabled && document.cookie.indexOf(cookieAlertValue) === -1) {
  vwCookieAlertBloc += "<div class='vw-cookie-alert'>";
  vwCookieAlertBloc += "En poursuivant votre navigation sans modifier vos paramètres de cookies, vous acceptez l'utilisation des cookies à des fins de mesure d'audience. Pour en savoir plus, <a href='" + cookieAlertPage + "' onClick='javascript:cookieAlertMore();'>cliquez ici</a>";
  vwCookieAlertBloc += "<button onClick='javascript:cookieAlertClose();' style='font-weight:bold;font-size:20px;'>&#215;</button>";
  vwCookieAlertBloc += "</div>";
  $(document.body).append(vwCookieAlertBloc);
  setTimeout(function() {
    ga('send', 'event', 'Alerte cookies', 'Ouverture', {'nonInteraction': 1});
  }, 2000);

}
